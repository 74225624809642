<template>
  <div id="elementId" style="display: flex; flex-direction: row">
    <l-map
      ref="map"
      :zoom="zoom"
      :center="originLatLon || destinationLatLon"
      :style="
        `border-radius: 15px;  min-height: ${height}; width: ${width};` +
        mapStyle
      "
    >
      <l-control-scale position="bottomleft" :imperial="false" />
      <l-tile-layer :url="tileLayer" :attribution="mapAttribution" />

      <l-marker v-if="originLatLon" :lat-lng="originLatLon" :icon="originIcon">
      </l-marker>
      <l-marker
        v-if="destinationLatLon"
        :lat-lng="destinationLatLon"
        :icon="destinationIcon"
      ></l-marker>
      <l-geo-json
        v-for="(geo, i) in geojsonRoutes"
        :geojson="geo"
        :key="i"
        :optionsStyle="
          checkedTransportsDict[geo.transportType]
            ? { color: $vuetify.theme.themes.light.primary, weight: 4 }
            : { color: mapRouteColor, weight: 4 }
        "
      />
    </l-map>
    <v-snackbar v-model="error.show" bottom right multi-line color="error">
      <v-icon>warning</v-icon>
      {{ error.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="error.show = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<script>
import { geoJSON, latLng, latLngBounds } from "leaflet";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet/dist/leaflet.css";
import Vue from "vue";
import {
  LControlScale, LGeoJson, LMap, LMarker, LPolyline, LPopup, LTileLayer, LTooltip
} from "vue2-leaflet";
// import { config } from "../config";
// import L from "leaflet";
import { world } from "../countries.geo";
// import "leaflet-iconmaterial/dist/leaflet.icon-material";
import "leaflet-iconmaterial-ext/dist/leaflet.icon-material";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import customMarkers from "../../public/custom_markers_full.json";
import {
  fetchSharingstationsWithinRadius, fetchTrainstationsWithRadius
} from "../api";

export default Vue.extend({
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LControlScale,
    LPopup,
    LTooltip,
    LPolyline,
  },
  props: {
    disableAutoFocus: { type: Boolean, default: false },
    thirdparty: { type: Object, default: () => ({}) },
    geojsonRoutes: { type: Array, default: () => [] },
    originLonLatStr: { type: String, default: "" },
    destinationLonLatStr: { type: String, default: "" },
    height: { type: String, default: "300px" },
    width: { type: String, default: "100%" },
    zoomControl: { type: Boolean, default: true },
    gestureHandling: { type: Boolean, default: true },
    routes: { type: Array, default: () => [] },
    transports: { type: Array, default: () => [] },
    checkedProvidersDict: { type: Object, default: () => ({}) },
    checkedTransportsDict: { type: Object, default: () => ({}) },
    arrival: { type: String, default: undefined },
    departure: { type: String, default: undefined },
    mapStyle: { type: String, default: "" },
    mapRouteColor: { type: String, default: "green" },
    mapIconColor: { type: String, default: "green" },
    mapActiveCustomMarkerIconColor: { type: String, default: "red" },
    mapInactiveCustomMarkerIconColor: { type: String, default: "red" },
    radiusSharingVehiclesKm: { type: Number, default: 5 },
    radiusTrainstationsKm: { type: Number, default: 50 },
    // shouldShowCustomMarkers not used yet
    // shouldShowCustomMarkers: { type: Boolean, default: true },
    boundToCustomMarkersOnMount: { type: Boolean, default: false },
  },
  data: () => ({
    fitBoundsDelay: 300,
    SubClustersDictById: {},
    error: { show: false, msg: "" },
    myGeojSonLayerGroup: undefined,
    /* dict  which contains the cluster layer of different transporttypes around point locationLatLon of the markers contained in 
    mobilityLayerGroupsDict of form {origin: {transportType1: layerClusterObj, locationLatLon: [array of lat, lon] }} */
    mobilityClusterGroupsDict: {
      origin: {
        layers: {},
        locationLatLon: undefined,
      },
      destination: {
        layers: {},
        locationLatLon: undefined,
      },
    },
    customClusterGroupsDict: {
      origin: {
        layers: {},
        locationLatLon: undefined,
      },
      destination: {
        layers: {},
        locationLatLon: undefined,
      },
    },
    customLayerGroupsDict: {
      origin: {
        layers: {},
        locationLatLon: undefined,
      },
      destination: {
        layers: {},
        locationLatLon: undefined,
      },
    },
    /* dict with providers for each origin and destination around point locationLatLon of 
    form {origin: { provider1: { marker: [array of marker] }, locationLatLon: [array of lat, lon] }} */
    mobilityLayerGroupsDict: {
      origin: {
        layers: {},
        locationLatLon: undefined,
      },
      destination: {
        layers: {},
        locationLatLon: undefined,
      },
    },
    trainstationsLayerGroupDict: {
      // origin/destination coordinates in 'location' for which the layerGroups were computed
      origin: {
        fernverkehr: undefined,
        regionalverkehr: undefined,
        locationLatLon: undefined,
      },
      destination: {
        fernverkehr: undefined,
        regionalverkehr: undefined,
        locationLatLon: undefined,
      },
    },
    originLatLon: undefined,
    destinationLatLon: undefined,
    mapAttribution: "",

    bounds: null,
    mbtoken: "",
    zoom: 16,
    center: [47.31322, -1.319482],
    sidecol: "",
    checkedProvidersCpyDict: {},
    // Threshold from which on regional trainstations should be shown
    zoomThresholdTrainstations: 12,
  }),

  computed: {
    destinationIcon() {
      return L.icon({
        iconUrl: "marker-icon-2x-red.png",
        shadowUrl: "marker-shadow.png",
        iconSize: [20, 35], // size of the icon
      });
    },
    originIcon() {
      return L.icon({
        iconUrl: "marker-icon-2x-blue.png",
        shadowUrl: "marker-shadow.png",
        iconSize: [20, 35], // size of the icon
      });
    },
    tileLayer() {
      // if (this.$vuetify.theme.isDark)
      //   return "https://cartodb-basemaps-b.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png";
      // if (this.mbtoken)
      //   return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=${this.mbtoken}`;
      return "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png";
    },
    myColor() {
      return "blue";
    },
  },
  methods: {
    isDateSet() {
      const isArrivalSet = this.arrival && this.arrival !== "" ? true : false;
      const isDepartureSet =
        this.departure && this.departure !== "" ? true : false;
      return isArrivalSet || isDepartureSet;
    },
    computeLatLonObj(lonLatStr) {
      if (lonLatStr && lonLatStr.length > 0) {
        const lonLat = lonLatStr.split(",").map((val) => Number(val));
        return latLng(lonLat[1], lonLat[0]);
      } else {
        return undefined;
      }
    },
    setBounds(geojson) {
      if (!geojson?.type) return;
      this.$refs.map.mapObject.invalidateSize();
      const bounds = geoJSON(geojson).getBounds();
      this.$refs.map.mapObject.fitBounds(bounds);
      return bounds;
    },

    onMapReady() {
      this.setBounds(this.geojson);
      this.bounds = this.geojson;
    },
    /* set bounds with a specific radius around marker */
    setBoundsWithRadius(latLng, radiusKm) {
      var radius = radiusKm * 1000; // in meters.
      // create circle and remove the border and background
      let myCircle = L.circle(latLng, {
        weight: 0,
        fillOpacity: 0,
        radius: radius,
      });
      if (this.$refs.map?.mapObject) {
        this.$refs.map.mapObject.invalidateSize();
        let addedCircle = myCircle.addTo(this.$refs.map.mapObject);
        // set bounds according to the circle/radius
        this.$refs.map.mapObject.fitBounds(addedCircle.getBounds());
        return addedCircle.getBounds();
      }
    },
    fitBoundsWithDelay(bounds, delay) {
      setTimeout(() => this.$refs.map.mapObject.fitBounds(bounds), delay);
    },
    /* get germany bounds from file which contains geojson of many countries, 
    by adding a transparrent geojson and get its bounds */
    getGermanyBounds() {
      var myStyle = {
        fillColor: "grey",
        weight: 0,
        opacity: 0,
        fillOpacity: 0.0,
      };
      if (
        !this.germanyGeojsonLayerGroup ||
        this.$refs.map.mapObject.hasLayer(this.germanyGeojsonLayerGroup)
      ) {
        this.germanyGeojsonLayerGroup = L.geoJson(world, {
          style: myStyle,
          filter: function (feature, layer) {
            return feature.properties.name === "Germany";
          },
        }).addTo(this.$refs.map.mapObject);
      } else {
        this.$refs.map.mapObject.addLayer(this.germanyGeojsonLayerGroup);
      }

      let bounds = this.germanyGeojsonLayerGroup.getBounds();

      return bounds;
    },
    setGermanyBounds() {
      if (!this.$refs.map?.mapObject) {
        return;
      }

      let bounds = this.getGermanyBounds();

      this.$refs.map.mapObject.invalidateSize();

      this.$refs.map.mapObject.fitBounds(bounds);
      return bounds;
    },
    removeAllMobilityMarkers(target) {
      for (let tType of Object.keys(
        this.mobilityClusterGroupsDict[target].layers
      )) {
        if (
          tType !== "locationLatLon" &&
          this.$refs.map.mapObject.hasLayer(
            this.mobilityClusterGroupsDict[target].layers[tType]
          )
        ) {
          this.$refs.map.mapObject.removeLayer(
            this.mobilityClusterGroupsDict[target].layers[tType]
          );
        }
      }
      if (
        this.trainstationsLayerGroupDict[target].hasOwnProperty(
          "fernverkehr"
        ) &&
        this.trainstationsLayerGroupDict[target].fernverkehr
      ) {
        if (
          this.$refs.map.mapObject.hasLayer(
            this.trainstationsLayerGroupDict[target].fernverkehr
          )
        ) {
          this.$refs.map.mapObject.removeLayer(
            this.trainstationsLayerGroupDict[target].fernverkehr
          );
        }
      }
      if (
        this.trainstationsLayerGroupDict[target].hasOwnProperty(
          "regionalverkehr"
        ) &&
        this.trainstationsLayerGroupDict[target].regionalverkehr
      ) {
        if (
          this.$refs.map.mapObject.hasLayer(
            this.trainstationsLayerGroupDict[target].regionalverkehr
          )
        ) {
          this.$refs.map.mapObject.removeLayer(
            this.trainstationsLayerGroupDict[target].regionalverkehr
          );
        }
      }

      // clear origin or destination target object
      this.mobilityClusterGroupsDict[target].layers = {};
      this.mobilityClusterGroupsDict[target].locationLatLon = undefined;
      this.customClusterGroupsDict[target].layers = {};
      this.customClusterGroupsDict[target].locationLatLon = undefined;
      this.trainstationsLayerGroupDict[target].layers = {};
      this.trainstationsLayerGroupDict[target].locationLatLon = undefined;
    },
    // show markers of mobility possibilities around the marker of the target (origin or destination)
    showTrainstationsMarkers(point, radiusKm, target) {
      // return if a point is not given -> means that the target was removed from input-field
      if (!point) {
        return;
      }
      // if location was chosen previously for the target, then don't fetch again from server, but load it locally
      if (
        this.trainstationsLayerGroupDict[target]?.locationLatLon &&
        point.lat ===
          this.trainstationsLayerGroupDict[target]?.locationLatLon[0] &&
        point.lng ===
          this.trainstationsLayerGroupDict[target]?.locationLatLon[1]
      ) {
        if (this.$refs.map.mapObject.getZoom() < 12) {
          // check whether the layer of the trainstations already exists and add it if not, otherwise add it
          if (
            !this.$refs.map.mapObject.hasLayer(
              this.trainstationsLayerGroupDict[target].fernverkehr
            )
          ) {
            this.trainstationsLayerGroupDict[target].fernverkehr.addTo(
              this.$refs.map.mapObject
            );
          }
        } else {
          if (
            !this.$refs.map.mapObject.hasLayer(
              this.trainstationsLayerGroupDict[target].fernverkehr
            )
          ) {
            this.trainstationsLayerGroupDict[target].fernverkehr.addTo(
              this.$refs.map.mapObject
            );
          }
          if (
            !this.$refs.map.mapObject.hasLayer(
              this.trainstationsLayerGroupDict[target].regionalverkehr
            )
          ) {
            this.trainstationsLayerGroupDict[target].regionalverkehr.addTo(
              this.$refs.map.mapObject
            );
          }
        }
        return;
      }
      // call API-function to retrieve all train stations around a point with a specified radius
      fetchTrainstationsWithRadius({
        trainTypes: ["fernverkehr", "regionalverkehr"],
        radiusKm: radiusKm,
        pointLonLat: [point.lng, point.lat],
      })
        .then((res) => {
          let trainstationsMapArray = res.stations.map((station) => {
            return {
              materialIcon: "train",
              verbund: station.verbund,
              name: station.name,
              products: station.products,
              lonLat: [station.lon, station.lat],
            };
          });
          let markers = { fernverkehr: [], regionalverkehr: [] };
          trainstationsMapArray.forEach((station) => {
            let publicTransportType;
            let iconColor;
            if (station.products.includes("fernverkehr")) {
              publicTransportType = "fernverkehr";
              iconColor = "blue";
            } else if (station.products.includes("regionalverkehr")) {
              publicTransportType = "regionalverkehr";
              iconColor = "#3b8eed";
            }
            const icon = this.createDivIcon("train", iconColor);
            const marker = L.marker(
              this.computeLatLonObj(station.lonLat.join(",")),
              {
                icon: icon,
              }
            ).bindPopup(station.name);
            try {
              markers[publicTransportType].push(marker);
            } catch (err) {
              // output error message as snackbar
              this.error.msg = err;
              this.error.show = true;
            }
          });
          const fernverkehrLayerGroup = new L.LayerGroup(markers.fernverkehr);
          const regionalverkehrLayerGroup = new L.LayerGroup(
            markers.regionalverkehr
          );
          if (this.$refs.map.mapObject.getZoom() < 12) {
            fernverkehrLayerGroup.addTo(this.$refs.map.mapObject);
          } else {
            fernverkehrLayerGroup.addTo(this.$refs.map.mapObject);
            regionalverkehrLayerGroup.addTo(this.$refs.map.mapObject);
          }
          let currentLocation;
          if (target === "origin") {
            if (!this.originLonLatStr) {
              currentLocation = point;
            } else {
              currentLocation = [
                JSON.parse(this.originLonLatStr.split(",")[1]),
                JSON.parse(this.originLonLatStr.split(",")[0]),
              ];
            }
          } else if (target === "destination") {
            currentLocation = [
              JSON.parse(this.destinationLonLatStr.split(",")[1]),
              JSON.parse(this.destinationLonLatStr.split(",")[0]),
            ];
          }
          this.trainstationsLayerGroupDict[target] = {
            ...this.trainstationsLayerGroupDict[target],
            fernverkehr: fernverkehrLayerGroup,
            regionalverkehr: regionalverkehrLayerGroup,
            locationLatLon: currentLocation,
          };
        })
        .catch((err) => {
          // output error message as snackbar
          this.error.msg = err;
          this.error.show = true;
        });
    },
    /* check if both locations which are in different format are same.
    First one is in dict-format {lat: number, lon: number} and the other in array-format [lat, lon] */
    latLonDictAndArrayAreEqual(latLonDict, latLonArray) {
      if (!latLonArray || !latLonArray) {
        return false;
      }
      const locAreSame =
        latLonDict &&
        latLonDict.lat === latLonArray[0] &&
        latLonDict.lng === latLonArray[1];
      return locAreSame;
    },
    // show markers of mobility possibilities around the marker of the target (origin or destination)
    showMobilityMarkers(point, radiusKm, target) {
      // return if a point is not given -> means that the target was removed from input-field
      if (!point) {
        return;
      }
      /* check first whether the markers for the specified target (origin or destination) 
      already exists for the passed point */
      if (
        this.latLonDictAndArrayAreEqual(
          point,
          this.mobilityClusterGroupsDict[target]?.locationLatLon
        )
      ) {
        /* loop over the clusterLayers of all transportTypes and add the clusterLayers 
        to the map if it doesnt exist there already */
        for (let clusterKey of Object.keys(
          this.mobilityClusterGroupsDict[target].layers
        )) {
          if (clusterKey !== "locationLatLon") {
            if (
              !this.$refs.map.mapObject.hasLayer(
                this.mobilityClusterGroupsDict[target].layers[clusterKey]
              )
            ) {
              this.$refs.map.mapObject.addLayer(
                this.mobilityClusterGroupsDict[target].layers[clusterKey]
              );
            }
          }
        }
        return;
      }

      // retrieve override locations around origin/destination from API
      const sharingStationsOptions = {
        pointLonLat: [point.lng, point.lat],
        radiusKm: radiusKm,
        limit: undefined,
        vehicleTypes: undefined,
        providerNames: undefined,
        returnOepvStations: undefined,
      };

      fetchSharingstationsWithinRadius(sharingStationsOptions).then(
        (transports) => {
          const tIds = transports.map((t) => t.transportId);
          let transportsCpy = [...this.transports];
          let transportsWithSharingstations = [];
          transportsCpy.forEach((t) => {
            if (tIds.includes(t.id)) {
              let overrides = transports.filter(
                (tInner) => tInner.transportId === t.id
              );
              t = { ...t, routing: { overrideLocations: overrides } };
              transportsWithSharingstations.push(t);
            }
          });
          let filteredOverridesProviderMapArray =
            transportsWithSharingstations.map((t) => ({
              materialIcon: t.ui.materialIcon,
              providerName: t.ui.providerName,
              transportType: t.transportType,
              overrideLocations: t.routing.overrideLocations,
            }));

          filteredOverridesProviderMapArray =
            filteredOverridesProviderMapArray.filter(
              (t) => t.overrideLocations.length > 0
            );

          let markersByProvider = {};
          let markersByTransportType = {};
          filteredOverridesProviderMapArray.forEach((overrideProviderMap) => {
            const icon = this.createMaterialIcon(
              overrideProviderMap.materialIcon,
              this.mapIconColor
            );
            overrideProviderMap.overrideLocations.forEach((lonLat, i) => {
              let marker = L.marker(
                this.computeLatLonObj(lonLat.lonLat.join(",")),
                {
                  icon: icon,
                }
              );

              marker.bindPopup(overrideProviderMap.providerName);
              // if provider is already added then push the marker into its array, otherwise create a new dict-element
              if (overrideProviderMap.providerName in markersByProvider) {
                markersByProvider[overrideProviderMap.providerName].marker.push(
                  marker
                );
              } else {
                markersByProvider[overrideProviderMap.providerName] = {
                  marker: [marker],
                  providerName: overrideProviderMap.providerName,
                  highlighted: false,
                  materialIcon: overrideProviderMap.materialIcon,
                };
              }

              // if vehicle-Type is already added then push the marker into its array, otherwise create a new dict-element
              if (overrideProviderMap.transportType in markersByTransportType) {
                markersByTransportType[
                  overrideProviderMap.transportType
                ].marker.push(marker);
              } else {
                markersByTransportType[overrideProviderMap.transportType] = {
                  marker: [marker],
                  providerName: overrideProviderMap.providerName,
                  highlighted: false,
                  materialIcon: overrideProviderMap.materialIcon,
                };
              }
            });
          });

          // add marker-objects of providers like RegioMobil
          for (const providerKey of Object.keys(markersByProvider)) {
            let myMarkers = markersByProvider[providerKey].marker;
            this.mobilityLayerGroupsDict[target].layers[providerKey] = {
              marker: myMarkers,
              highlighted: this.checkedProvidersDict[providerKey],
              materialIcon: markersByProvider[providerKey].materialIcon,
              providerName: markersByProvider[providerKey].providerName,
            };
          }

          // add marker-objects of transport-types and -combinations like 'car', 'carsharing' etc.
          for (const transportTypeKey of Object.keys(markersByTransportType)) {
            let icon = markersByTransportType[transportTypeKey].materialIcon;
            let clustering = this.createCustomClusterByMaterialIcon(
              icon,
              target,
              "mobility"
            );
            let myMarkers = markersByTransportType[transportTypeKey].marker;
            // let layerGroup = new L.LayerGroup(myMarkers);
            clustering.addLayers(myMarkers);
            this.$refs.map.mapObject.addLayer(clustering);
            this.mobilityClusterGroupsDict[target].layers[transportTypeKey] =
              clustering;
          }
        }
      );
    },
    // show markers of mobility possibilities around the marker of the target (origin or destination)
    showCustomMarkers(point, radiusKm, target) {
      // return if a point is not given -> means that the target was removed from input-field
      if (!point) {
        return;
      }
      /* check first whether the markers for the specified target (origin or destination) 
      already exists for the passed point */

      /* check first whether the markers for the specified target (origin or destination) 
      already exists for the passed point */
      if (
        this.latLonDictAndArrayAreEqual(
          point,
          this.customClusterGroupsDict[target]?.locationLatLon
        )
      ) {
        /* loop over the clusterLayers of all transportTypes and add the clusterLayers 
        to the map if it doesnt exist there already */
        for (let clusterKey of Object.keys(
          this.customClusterGroupsDict[target].layers
        )) {
          if (clusterKey !== "locationLatLon") {
            if (
              !this.$refs.map.mapObject.hasLayer(
                this.customClusterGroupsDict[target].layers[clusterKey]
              )
            ) {
              this.$refs.map.mapObject.addLayer(
                this.customClusterGroupsDict[target].layers[clusterKey]
              );
            }
          }
        }
        return;
      }

      let filteredOverridesProviderMapArray = customMarkers["spaces"].map(
        (t) => ({
          materialIcon: t.materialIcon,
          providerName: t.name,
          transportType: "",
          locations: t.locations,
        })
      );

      filteredOverridesProviderMapArray =
        filteredOverridesProviderMapArray.filter((t) => t.locations.length > 0);

      let markersByProvider = {};
      let markersByTransportType = {};
      filteredOverridesProviderMapArray.forEach((overrideProviderMap) => {
        overrideProviderMap.locations.forEach((loc, i) => {
          let iconColor = "red";
          if (loc.status === "ready") {
            iconColor = this.mapActiveCustomMarkerIconColor;
          } else {
            iconColor = this.mapInactiveCustomMarkerIconColor;
          }

          const icon = this.createDivIcon(
            overrideProviderMap.materialIcon,
            iconColor
          );
          let marker = L.marker(this.computeLatLonObj(loc.lonLat.join(",")), {
            icon: icon,
          });

          marker.bindPopup(loc.name);
          // if provider is already added then push the marker into its array, otherwise create a new dict-element
          if (overrideProviderMap.providerName in markersByProvider) {
            markersByProvider[overrideProviderMap.providerName].marker.push(
              marker
            );
          } else {
            markersByProvider[overrideProviderMap.providerName] = {
              marker: [marker],
              providerName: overrideProviderMap.providerName,
              highlighted: false,
              materialIcon: overrideProviderMap.materialIcon,
            };
          }

          // if vehicle-Type is already added then push the marker into its array, otherwise create a new dict-element
          if (overrideProviderMap.transportType in markersByTransportType) {
            markersByTransportType[
              overrideProviderMap.transportType
            ].marker.push(marker);
          } else {
            markersByTransportType[overrideProviderMap.transportType] = {
              marker: [marker],
              providerName: overrideProviderMap.providerName,
              highlighted: false,
              materialIcon: overrideProviderMap.materialIcon,
            };
          }
        });
      });
      // add marker-objects of providers like RegioMobil
      for (const providerKey of Object.keys(markersByProvider)) {
        let myMarkers = markersByProvider[providerKey].marker;
        this.customLayerGroupsDict[target].layers[providerKey] = {
          marker: myMarkers,
          highlighted: true, //this.checkedProvidersDict[providerKey],
          materialIcon: markersByProvider[providerKey].materialIcon,
          providerName: markersByProvider[providerKey].providerName,
        };
      }

      // add marker-objects of transport-types and -combinations like 'car', 'carsharing' etc.
      for (const transportTypeKey of Object.keys(markersByTransportType)) {
        let icon = markersByTransportType[transportTypeKey].materialIcon;
        let clustering = this.createCustomClusterByMaterialIcon(
          icon,
          target,
          "custom"
        );

        //  this.$refs.map.mapObject.removeLayer(
        //     this.trainstationsLayerGroupDict[target].fernverkehr
        //   );
        let myMarkers = markersByTransportType[transportTypeKey].marker;
        clustering.addLayers(myMarkers);
        this.$refs.map.mapObject.addLayer(clustering);

        this.customClusterGroupsDict[target].layers[transportTypeKey] =
          clustering;
      }

      //});
    },
    createCustomClusterByMaterialIcon(
      materialIcon,
      target,
      clusterType = "mobility"
    ) {
      return new L.MarkerClusterGroup({
        iconCreateFunction: function (cluster) {
          // if (cluster._leaflet_id in Object.keys(this.SubClustersDictById)) {
          this.SubClustersDictById[cluster._leaflet_id] = {
            layer: cluster,
            materialIcon: materialIcon,
          };
          /* setting the markers of the cluster on the top layer on the map, so other markers don't overlap 
          them "clusterclick" and "spiderfy" events didn't triggers */
          cluster.on("click", function (e) {
            for (let marker of cluster.getAllChildMarkers()) {
              marker.setZIndexOffset(100);
            }
          });

          let highlightedIcon = this.createHighlightedMobilityClusterIcon(
            cluster,
            target,
            materialIcon,
            clusterType
          );
          if (highlightedIcon) {
            return highlightedIcon;
          }
        }.bind(this),
      });
    },
    /* divIcon for cluster with specified background-color and with the number of markers 
    on the left and the materialIcon on the right side  */
    createClusterDivIcon(markersLength, clusterColor, materialIcon) {
      // if number of markers is over 100 then show just indication of that to avoid exceedance of circle div
      const numberText =
        markersLength < 100 ? markersLength : ">" + markersLength;
      var html =
        '<div class="circle flex-box-center-items-horizontal" style="align-items: center; \
          opacity: 0.8;  background-color: ' +
        clusterColor +
        ' !important;"><span>' +
        numberText +
        '</span><span class="material-icons" style=" font-size: 15px">' +
        materialIcon +
        "</span></div>";

      const icon = L.divIcon({
        html: html,
        className: "mycluster",
        iconSize: L.point(32, 32),
      });
      return icon;
    },
    /* toggle Marker: add highlighting or remove highlighting
    passing the cateogory and a dict containing the markers of differenct categories 
    of form {category: obj: {marker: [array of marker-objects]}} */
    toggleMarker(markerDict, changedElement, target) {
      let providerMarkerObj = markerDict[changedElement];
      let providerMarker;
      if (!providerMarkerObj) {
        return;
      }
      providerMarkerObj.highlighted = !providerMarkerObj.highlighted;

      // toggle the highlight-value
      for (let subCluster of Object.keys(this.SubClustersDictById)) {
        let highlightedIcon = this.createHighlightedMobilityClusterIcon(
          this.SubClustersDictById[subCluster].layer,
          target,
          this.SubClustersDictById[subCluster].materialIcon,
          "mobility",
          changedElement
        );

        if (highlightedIcon) {
          this.SubClustersDictById[subCluster].layer.setIcon(highlightedIcon);
        }
      }

      // get all markers of the selected provider
      if (providerMarkerObj) {
        providerMarker = providerMarkerObj.marker;
      } else {
        return;
      }
      // if origin or destination is not initialized then return
      if (!providerMarker) {
        return;
      }
      // loop over all markers of a providerto highlight or remove highlighting
      providerMarker.forEach((marker) => {
        if (marker instanceof L.Marker) {
          const overrideProviderMap = {
            materialIcon: markerDict[changedElement].materialIcon,
          };
          const color = markerDict[changedElement].highlighted
            ? this.$vuetify.theme.themes.light.primary
            : this.mapIconColor;
          const icon = this.createMaterialIcon(
            overrideProviderMap.materialIcon,
            color
          );
          marker.setIcon(icon);
        }
      });
    },
    /* creates icons with a custom shape and content defined as Div-block */
    createDivIcon(materialIcon, color) {
      var html =
        '<div class="square-round-edges flex-box-center-items-horizontal" style="align-items: center; \
          opacity: 0.8;  background-color: ' +
        color +
        ' !important;"><span>' +
        '</span><span class="material-icons" style="color: white; font-size: 15px">' +
        materialIcon +
        "</span></div>";
      return L.divIcon({
        html: html,
        className: "mycluster",
        iconSize: L.point(22, 22),
      });
    },
    /* creates icons with an materialIcon inside for marker in a typical Marker-Shape */
    createMaterialIcon(materialIcon, color) {
      return L.IconMaterial.icon({
        icon: materialIcon, // Name of Material icon
        iconColor: color, // Material icon color (could be rgba, hex, html name...)
        markerColor: "white", // Marker fill color
        outlineColor: color, // Marker outline color
        outlineWidth: 2, // Marker outline width
        iconSize: [35, 35], // Width and height of the icon
      });
    },
    createHighlightedMobilityClusterIcon(
      cluster,
      target,
      materialIcon,
      clusterType = "mobility"
    ) {
      let color = this.mapIconColor;
      let finished = false;
      let icon = undefined;
      let clusterDict;
      if (clusterType === "mobility") {
        clusterDict = this.mobilityLayerGroupsDict;
      } else if ((clusterType = "custom")) {
        clusterDict = this.customLayerGroupsDict;
      }
      for (const providerKey of Object.keys(clusterDict[target].layers)) {
        if (clusterDict[target].layers[providerKey].highlighted) {
          for (const markerProvider of clusterDict[target].layers[providerKey]
            .marker) {
            if (
              cluster
                .getAllChildMarkers()
                .filter(
                  (clusterMarker) =>
                    markerProvider._leaflet_id === clusterMarker._leaflet_id
                ).length > 0
            ) {
              color = "red";
              finished = true;
              break;
            }
          }
        }

        icon = this.createClusterDivIcon(
          cluster.getAllChildMarkers().length,
          color,
          materialIcon
        );

        if (finished) {
          break;
        }
      }
      return icon;
    },
    boundToCustomMarkers() {
      const latLngLocations = customMarkers["spaces"][0].locations.map(
        (loc) => [loc.lonLat[1], loc.lonLat[0]]
      );

      let mybounds = latLngBounds(latLngLocations);
      this.$refs.map.mapObject.invalidateSize();
      this.fitBoundsWithDelay(mybounds, 1);
      const centerLatLng = mybounds.getCenter();
      this.showMobilityMarkers(
        centerLatLng,
        this.radiusSharingVehiclesKm,
        "origin"
      );
      this.showTrainstationsMarkers(
        centerLatLng,
        this.radiusTrainstationsKm,
        "origin"
      );
      this.showCustomMarkers(centerLatLng, 100, "origin");
    },
  },
  async created() {
    this.checkedProvidersCpyDict = { ...this.checkedProvidersDict };
    if (!this.thirdparty) {
      return;
    }
    this.mbtoken = this.thirdparty.mapbox?.apikey || "";
  },
  mounted() {
    const resizeObserver = new ResizeObserver((entries) => {
      // adapt resize of map after user clicks on "show available transports" panel
      if (this.bounds && this.$refs.map?.mapObject) {
        this.$refs.map.mapObject.invalidateSize();
        this.$refs.map.mapObject.fitBounds(this.bounds);
        if (this.boundToCustomMarkersOnMount) {
          this.boundToCustomMarkers();
        }
      } else {
        this.setGermanyBounds();
        if (this.boundToCustomMarkersOnMount) {
          this.boundToCustomMarkers();
        }
      }
    });

    this.$refs.map?.mapObject.on(
      "zoomend",
      function (e) {
        const zoomLevel = this.$refs.map?.mapObject.getZoom();

        for (const target of ["origin", "destination"]) {
          if (zoomLevel > this.zoomThresholdTrainstations) {
            if (this.trainstationsLayerGroupDict[target].regionalverkehr) {
              if (
                !this.$refs.map?.mapObject.hasLayer(
                  this.trainstationsLayerGroupDict[target].regionalverkehr
                )
              ) {
                this.$refs.map?.mapObject.addLayer(
                  this.trainstationsLayerGroupDict[target].regionalverkehr
                );
              }
            }
          } else {
            if (
              this.trainstationsLayerGroupDict[target].regionalverkehr &&
              this.$refs.map?.mapObject.hasLayer(
                this.trainstationsLayerGroupDict[target].regionalverkehr
              )
            ) {
              this.$refs.map?.mapObject.removeLayer(
                this.trainstationsLayerGroupDict[target].regionalverkehr
              );
            }
          }

          for (const clusterName of Object.keys(this.SubClustersDictById)) {
            const cluster = this.SubClustersDictById[clusterName];

            let highlightedIcon = this.createHighlightedMobilityClusterIcon(
              cluster.layer,
              target,
              cluster.materialIcon,
              "mobility"
            );
            if (highlightedIcon) {
              cluster.layer.setIcon(highlightedIcon);
            }
          }
        }
      }.bind(this)
    );

    resizeObserver.observe(document.getElementById("elementId"));

    // if origin and destination points are given on Map-initialization then assign them to show the marker
    this.originLatLon = this.computeLatLonObj(this.originLonLatStr);
    this.destinationLatLon = this.computeLatLonObj(this.destinationLonLatStr);
    let bounds;
    // if both origin and destination are set, then bound to them, otherwise bound to germany map
    if (this.originLatLon && this.destinationLatLon) {
      bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
      this.$refs.map.mapObject.fitBounds(bounds);
    } else {
      bounds = this.setGermanyBounds();
    }
    // showing germany map
    this.bounds = bounds;
    // this.setBounds(this.geojsonRoutes[0]);
  },
  watch: {
    height() {
      let bounds = undefined;
      if (
        !this.originLatLon &&
        !this.destinationLatLon &&
        (!this.geojsonRoutes ||
          (this.geojsonRoutes && this.geojsonRoutes.length === 0))
      ) {
        this.setGermanyBounds();
        bounds = this.getGermanyBounds();
      } else if (this.originLatLon && this.destinationLatLon) {
        bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
        this.$refs.map.mapObject.fitBounds(bounds);
      } else if (this.geojsonRoutes && this.geojsonRoutes.length > 0) {
        bounds = this.setBounds(this.geojsonRoutes[0]);
      } else if (this.originLatLon && !this.destinationLatLon) {
        bounds = this.setBoundsWithRadius(this.originLatLon, 2);
      } else if (!this.originLatLon && this.destinationLatLon) {
        bounds = this.setBoundsWithRadius(this.destinationLatLon, 2);
      }
      this.bounds = bounds;
    },
    geojsonRoutes() {
      this.setBounds(this.geojsonRoutes[0]);
    },
    originLonLatStr() {
      this.originLatLon = this.computeLatLonObj(this.originLonLatStr);
      if (this.disableAutoFocus) {
        let bounds;
        // if both origin and destination are set, then bound to them, otherwise bound to germany map
        if (this.originLatLon && this.destinationLatLon) {
          bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
          this.$refs.map.mapObject.invalidateSize();
          this.fitBoundsWithDelay(bounds, 1);
        } else {
          bounds = this.setGermanyBounds();
        }
        return;
      }
      let bounds = undefined;
      if (this.originLatLon) {
        bounds = this.setBoundsWithRadius(this.originLatLon, 2);
        this.showMobilityMarkers(
          this.originLatLon,
          this.radiusSharingVehiclesKm,
          "origin"
        );
        this.showTrainstationsMarkers(
          this.originLatLon,
          this.radiusTrainstationsKm,
          "origin"
        );
      } else if (
        this.destinationLatLon &&
        this.originLatLon &&
        this.isDateSet()
      ) {
        bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
        this.showMobilityMarkers(
          this.originLatLon,
          this.radiusSharingVehiclesKm,
          "origin"
        );
        this.showTrainstationsMarkers(
          this.originLatLon,
          this.radiusTrainstationsKm,
          "origin"
        );

        this.$refs.map.mapObject.fitBounds(bounds);
      } else if (!this.destinationLatLon && !this.originLatLon) {
        this.removeAllMobilityMarkers("origin");
        this.removeAllMobilityMarkers("destination");
        /* if destination and origin are resetted, then show germany map again.
        Using timeout becasue for some reason a map in Africa is shown after setting germany map*/
        setTimeout(() => this.setGermanyBounds(), this.fitBoundsDelay);
      } else if (!this.originLatLon && this.destinationLatLon) {
        bounds = this.setBoundsWithRadius(this.destinationLatLon, 2);
        this.removeAllMobilityMarkers("origin");
      }
      this.bounds = bounds;
    },
    destinationLonLatStr() {
      this.destinationLatLon = this.computeLatLonObj(this.destinationLonLatStr);

      if (this.disableAutoFocus) {
        let bounds;
        // if both origin and destination are set, then bound to them, otherwise bound to germany map
        if (this.originLatLon && this.destinationLatLon) {
          bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
          this.$refs.map.mapObject.invalidateSize();

          this.fitBoundsWithDelay(bounds, 1);
        } else {
          bounds = this.setGermanyBounds();
        }
        return;
      }
      let bounds = undefined;
      // if only destination is set, then focus to it
      if (this.destinationLatLon) {
        bounds = this.setBoundsWithRadius(this.destinationLatLon, 2);

        this.removeAllMobilityMarkers("destination");
        this.showMobilityMarkers(
          this.destinationLatLon,
          this.radiusSharingVehiclesKm,
          "destination"
        );
        this.showTrainstationsMarkers(
          this.destinationLatLon,
          this.radiusTrainstationsKm,
          "destination"
        );
      }
      // if both origin and destination are set then set the bounds to see both markers
      else if (
        this.destinationLatLon &&
        this.originLatLon &&
        this.isDateSet()
      ) {
        bounds = L.latLngBounds([this.originLatLon, this.destinationLatLon]);
        this.removeAllMobilityMarkers("destination");
        this.showMobilityMarkers(
          this.destinationLatLon,
          this.radiusSharingVehiclesKm,
          "destination"
        );
        this.showTrainstationsMarkers(
          this.destinationLatLon,
          this.radiusTrainstationsKm,
          "destination"
        );
        this.$refs.map.mapObject.fitBounds(bounds);
        // this.$refs.map.mapObject.fitBounds(bounds);
      } else if (!this.destinationLatLon && !this.originLatLon) {
        this.removeAllMobilityMarkers("origin");
        this.removeAllMobilityMarkers("destination");
        /* if destination and origin are resetted, then show germany map again.
        Using timeout becasue for some reason a map in Africa is shown after setting germany map*/
        setTimeout(() => this.setGermanyBounds(), this.fitBoundsDelay);
        bounds = this.getGermanyBounds();
      } else if (this.originLatLon && !this.destinationLatLon) {
        bounds = this.setBoundsWithRadius(this.originLatLon, 2);
        this.removeAllMobilityMarkers("destination");
      }
      this.bounds = bounds;
    },
    arrival() {
      if (this.destinationLatLon && this.originLatLon && this.isDateSet()) {
        let bounds = L.latLngBounds([
          this.originLatLon,
          this.destinationLatLon,
        ]);
        this.markerTargetsToShow = ["origin", "destination"];
        this.$refs.map.mapObject.fitBounds(bounds);
        this.bounds = bounds;
      }
    },
    departure() {
      if (this.destinationLatLon && this.originLatLon && this.isDateSet()) {
        let bounds = L.latLngBounds([
          this.originLatLon,
          this.destinationLatLon,
        ]);

        this.$refs.map.mapObject.fitBounds(bounds);
        this.bounds = bounds;
      }
    },
    checkedProvidersDict: {
      handler: function (newVal, oldVal) {
        if (
          Object.keys(newVal).length !==
          Object.keys(this.checkedProvidersCpyDict).length
        ) {
          this.checkedProvidersCpyDict = { ...this.checkedProvidersDict };
          return;
        }

        const changedElement = Object.keys(newVal).filter(
          (key) => newVal[key] !== this.checkedProvidersCpyDict[key]
        );
        let providerName;
        try {
          providerName = changedElement[0];
        } catch {
          return;
        }

        this.toggleMarker(
          this.mobilityLayerGroupsDict.origin.layers,
          providerName,
          "origin"
        );
        this.toggleMarker(
          this.mobilityLayerGroupsDict.destination.layers,
          providerName,
          "destination"
        );

        this.checkedProvidersCpyDict = { ...this.checkedProvidersDict };
      },
      deep: true,
    },
  },
});
</script>
<style>
.circle {
  width: 32px;
  height: 32px;
  line-height: 32px;

  background-color: red;
  border-radius: 15px;
  text-align: center;
}
.square-round-edges {
  width: 22px;
  height: 22px;
  line-height: 22px;

  background-color: blue;
  border-radius: 5px;
  text-align: center;
}
</style>