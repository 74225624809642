var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.boxClass,staticStyle:{"cursor":"pointer","border-radius":"23px"},style:(_vm.borderStyle)},_vm._l((_vm.computedTransportGroup),function(transportPart,i){return _c('div',{key:i,class:_vm.boxClass,style:(_vm.singleSymbolArrowStyle(i))},[_c('v-avatar',{staticClass:"mb-0",attrs:{"color":_vm.isOverviewMode ? 'secondary !important' : ("" + _vm.color),"size":"40px","rounded":"circle"}},[_c('v-avatar',{staticClass:"mb-3 avatar2",staticStyle:{"margin-top":"12px"},attrs:{"color":"white !important","size":"34px","rounded":"circle"}},[(transportPart.ui.materialIcon)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(transportPart.ui.materialIcon))]):(
              transportPart.ui.svgNameIcon &&
              transportPart.ui.svgNameIcon == 'high_speed_train'
            )?_c('svg',{attrs:{"version":"1.0","xmlns":"http://www.w3.org/2000/svg","width":"24.000000pt","height":"24.000000pt","viewBox":"0 0 24.000000 24.000000","preserveAspectRatio":"xMidYMid meet"}},[_c('g',{attrs:{"transform":"translate(3.000000,22.000000) scale(0.0800000,-0.0800000)","fill":_vm.$vuetify.theme.themes.light.primary,"stroke":"none"}},[_c('path',{attrs:{"d":"M20 200 c0 -5 12 -10 28 -10 39 -1 102 -32 136 -69 l30 -31 -32 0\nc-32 0 -43 -14 -19 -23 8 -4 7 -6 -3 -6 -8 0 -26 6 -40 14 -14 8 -42 14 -63\n15 -36 0 -38 -2 -35 -27 2 -24 8 -29 36 -31 19 -2 41 0 50 4 10 4 35 6 56 4\n31 -1 38 1 33 13 -3 9 2 17 14 20 26 7 24 19 -8 58 -44 52 -183 104 -183 69z\nm40 -140 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10\n-10z m40 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4\n10 -10z"}}),_c('path',{attrs:{"d":"M20 135 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11 0\n-20 -7 -20 -15z"}}),_c('path',{attrs:{"d":"M80 134 c0 -8 10 -14 26 -14 28 0 25 15 -6 23 -13 3 -20 0 -20 -9z"}})])]):_vm._e()],1)],1),(
          _vm.boxAlignment === 'vertical' &&
          i < _vm.computedTransportGroup.length - 1 &&
          _vm.computedTransportGroup.length > 1
        )?_c('v-icon',{staticStyle:{"margin-top":"1px"},attrs:{"color":"white"}},[_vm._v("arrow_downward")]):(
          _vm.boxAlignment === 'horizontal' &&
          i < _vm.computedTransportGroup.length - 1 &&
          _vm.computedTransportGroup.length > 1
        )?_c('v-icon',{staticStyle:{"margin-left":"1px"},attrs:{"color":"white"}},[_vm._v("arrow_right")]):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }