import '@fontsource/carlito/400.css'
import '@fontsource/carlito/700.css'
import '@fontsource/carlito/400-italic.css'
import '@fontsource/carlito/700-italic.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f54a3d',
        secondary: '#55a699',
      },
      dark: {
        primary:   '#E84C05',
        secondary: '#CBCDCB',
      }
    },
  },
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de },
    current: 'de',
  },
});

export default vuetify
