<template>
  <component
    :disabled="pageLoading"
    :is="componentType"
    ref="input"
    :value="valueAsObject"
    :search-input.sync="term"
    :items="items"
    @focus="focused = true;itemSelected=false;eventCalled('focus', $event)
    "
    
    @blur="
      focused = false;
    "
    @input=" emitValue($event, null); itemSelected=true; eventCalled('input', $event)"
    v-on="allowGeolocation ? {'click:prepend-inner': geolocate} : {}"
    item-value="id"
    item-text="name"
    no-filter
    hide-no-data
    :loading="loading"
    :prepend-inner-icon="allowGeolocation ? 'my_location' : 'place'"
    :label="label"
    :placeholder="focused ? placeholder : ''"
    :menu-props="menuProps"
    filled
    hide-details
    clearable
  >
    <template v-slot:item="{item}">
      <v-icon>{{ item.geo ? "star" : "place" }}</v-icon>
      {{ item.name }}
    </template>
  </component>
</template>

<script>
import Vue from "vue";

import {VCombobox} from "vuetify/lib/components/VCombobox";
import {VSelect} from "vuetify/lib/components/VSelect";
import {decode} from "ngeohash";
import {fetchGeocoding} from "../api";

export default Vue.extend({
  name: "GeocoderSearch",

  props: {
    pageLoading: {type: Boolean, default: false},
    inputKey: {type: String, default: "key1"},
    value: {type: String, default: undefined},
    pois: {type: Array, default: () => []},
    focusPoint: {type: Array, default: undefined},
    limitFocusDist: {type: Number, default: 0},
    allowGeolocation: {type: Boolean, default: false},
    allowGeocoding: {type: Boolean, default: true},
    label: {type: String, default: "Adresse"},
    placeholder: {type: String, default: "Adresse oder Örtlichkeit"},
    menuProps: {type: Object, default: () => ({})},
    geocodeInputChanged: {type: Function},
  },

  data: () => ({
    // Timer for debouncing geocoding-request
    prevDeboucingTimer: 0,
    itemSelected: true,
    term: "",
    results: [],

    debounce: null,
    lastGeocodeCallId: 0,
    focused: false,
    changeSinceFocus: false,
    loading: false,
  }),
  watch: {
  term(oldVal, newVal) {
    // console.log("oldVal=",oldVal, "-newVal=",newVal);
    this.geocode()
  }
  },
  computed: {
    items() {
      let p = this.pois.filter(({name}) => fuzzySearch(name, this.term || ""));
      const r = this.results;

      if (p.length && r.length) return [...p, {divider: true}, ...r];
      else return [...p, ...r];
    },

    // interpret the string prop as ID and try to find a matching POI.
    // the result is used as input prop to the combobox.
    valueAsObject() {
      const v = this.value || null;
      const p = this.pois.filter((p) => p.id === v || p.name === v)[0];
      return p || (v ? {id: v, name: v} : null);
    },

    componentType() {
      if (this.allowGeocoding || this.pois.length > 14) return VCombobox;
      return VSelect;
    },
  },

  methods: {
    eventCalled(eventtype, val) {
      // console.log("eventCalled-eventtype=",eventtype, "-val=",val);
    },
    async emitValue(event, valueAsObject) {
      let value;
      // if valueAsObject is passed, then use that
      if (valueAsObject) {
        value = valueAsObject;
      }
      // else if an input event is passed then extract its value
      else if (event instanceof Event) {
        try {
          value = event.target.value;
        } catch {}
      } else if (!this.allowGeocoding && this.pois.length < 14) {
        /* if geocoding is not allowed and there are less then 14 destinations (so VSelect instead of VCombo is used)
      then retrieve the chose from the drop-down list and identify it by its ID. 
      Otherwise with VSelect if the passed value in 'event' is used, then we get just the id without the geo-code */
        value = this.pois.find((obj) => obj.id === event);
      }
      // if an input field is emptied than set also the destination/origin lonLat to empty object to delete it within App.vue
      else if (!event) {
        this.geocodeInputChanged({}, this.inputKey);
      } else {
        value = event;
      }
      let v = value?.id || value;
      this.$emit("input", v);
      // this.changeSinceFocus = false;
      // if update-Function is given from parent then call it to return the geocode-obj with lat-lon
      if (this.geocodeInputChanged && value) {
        // if the location is encoded as geohash then decode it to get the coordinates
        if (value.geo && !value.lonLat) {
          var lonLat = decode(value.geo);
          value.lonLat = [lonLat.longitude, lonLat.latitude];
        }
        this.geocodeInputChanged(value, this.inputKey);
      }
      // TODO: hide menu
    },
    /**
     * handles deboucing by clearing previous timers if it's still active and starts a new timer
     * and by that overrides the former one
     * @param func: function to call on timeout
     * @param delay: delay in millesec
     */
    useDebounce(func, delay) {
      const debouncedFunction = (...args) => {
        if (this.prevDeboucingTimer) {
          clearTimeout(this.prevDeboucingTimer);
        }
        this.prevDeboucingTimer = setTimeout(() => {
          func(...args);
        }, delay);
      };
      return debouncedFunction;
    },
    /**
     * calls the API endpoint for fetching the geocoding
     */
    async geocodeDebounced() {

      if (!this.allowGeocoding || !this.term || this.term.length < 3 || this.itemSelected) {
        this.results = [];
        if (this.itemSelected) {
          this.itemSelected = false;
        }
        
        return;
      }
      this.loading = true;
      let results = await fetchGeocoding(this.term);

      this.loading = false;
      
      // if an earlier call returns after a later call, ignore the result
      this.results = results;
    },
    /**
     * triggers the call of the debounced gecode-fetching function
     */
    geocode() {
      this.useDebounce(this.geocodeDebounced, 1000)();
    },
    async geolocate() {
      this.loading = true;
      this.results = await this.geolocateAddresses();
      this.$refs.input.focus();
      this.loading = false;
    },
    async geolocateAddresses() {
      return Error("API endpoint on CR-Server not implemented yet");
      const geolocate = () =>
        new Promise((res, rej) =>
          navigator.geolocation.getCurrentPosition(res, rej)
        );
      // const {
      //   coords: {longitude, latitude, accuracy},
      // } = await geolocate();
      // const geocoder = await selectService();
      // return geocoder.reverse([longitude, latitude]);
    },
  },
});

// TODO: use proper fuzzy search filter..
function fuzzySearch(hay, needle) {
  if (!hay || !needle) return true;
  (hay = hay.toLowerCase()), (needle = needle.toLowerCase());
  let n = -1,
    l;
  for (let i = 0; (l = needle[i++]); )
    if (!~(n = hay.indexOf(l, n + 1))) return false;
  return true;
}
</script>
