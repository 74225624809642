import { defineStore } from "pinia";

export const usePollStore = defineStore({
  id: "poll",
  state: () => ({
    pollPlz: "0",
    title: "",
    introductionSteps: [],
    pollDate: undefined,
    pollMonthsInOrganization: undefined,
    pollWorkingDays: undefined,
    pollHomeofficeDays: undefined,
    pollMonthsInOrganizationMax: undefined,
    pollWorkingDaysMax: undefined,
    pollHomeofficeDaysMax: undefined,
    usePoll: false,
    frequencyType: 'days',
    pollType: 'company',
    frequencies: [],
    numberPassengers: [],
    numberStars: [],
    transportUsed: []
  }),
  getters: {
    pollFrequency: (state) => {
      return (i) => state.frequencies.length > i ? state.frequencies[i].freq : 0;
    },
    numberPassengersOfTransport: (state) => {
      return (i) => state.numberPassengers.length > i ? state.numberPassengers[i].freq : 0;
    },
    isTransportUsed: (state) => {
      return (i) => state.transportUsed.length > i ? state.transportUsed[i] === true : false;
    },
    getNumberStars: (state) => {
      return (i) => state.numberStars.length > i ? state.numberStars[i].freq : 0;
    }
  },
  actions: {
    /**
     * reset just the survey input related to transport evaluation and choices 
     * (but doesn't concern the user's personal info like postal code or the months he is in the organization)
     */
    resetSuveyInput() {
      this.frequencies = [];
      this.numberPassengers = [];
      this.numberStars = [];
      this.transportUsed = [];
    },
    isPollPlzValid() {
      if (this.pollPlz === '0' || (Number(this.pollPlz) && !Number.isNaN(this.pollPlz) && this.pollPlz.length === 5)) {
        return true;
      } else {
        return false;
      }
    },
    isPollMonthsInOrganizationValid() {
      if (
        this.pollMonthsInOrganization === undefined ||
        (Number.isInteger(Number(
          this.pollMonthsInOrganization)
        ) &&
          this.pollMonthsInOrganization <=
          this.pollMonthsInOrganizationMax
          && this.pollMonthsInOrganization >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /*
    checking frequencies - depending on type percentage/year or days/week
    */
    isPollFrequenciesValid() {
      // event-type doesn't use frequencies
      if (this.pollType === 'event') {
        return true;
      }

      if (this.frequencies.length === 0) {
        return false;
      }
      else if (this.frequencyType === 'percentage') {
        const sum = this.frequencies.map((a) => a.freq).reduce((a, b) => a + b);

        return sum === 100;
      } else if (this.frequencyType === 'days') {
        const sum = this.frequencies.map((a) => a.freq).reduce((a, b) => a + b);
        return sum === Number(this.pollWorkingDays);
      } else {
        return false;
      }
    },
    isTransportsUsedValid() {
      if (this.pollType === 'event') {
        const sum = this.transportUsed.filter(m => m === true);
        return sum.length === 1 ? true : false;


      } else if (this.pollType === 'company') {
        return true;
      }
    },
    isPollWorkingDaysValid() {
      if (
        this.pollWorkingDays === undefined ||
        (Number.isInteger(Number(
          this.pollWorkingDays)
        ) &&
          this.pollWorkingDays <=
          this.pollWorkingDaysMax
          && this.pollWorkingDays >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollHomeofficeDaysValid() {
      if (
        this.pollHomeofficeDays === undefined ||
        (Number.isInteger(Number(
          this.pollHomeofficeDays)
        ) &&
          this.pollHomeofficeDays <=
          this.pollHomeofficeDaysMax
          && this.pollHomeofficeDays >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
    * returns validity of pollData
    */
    isPollValid() {
      // if the general settings are valid and 
      if (
        this.isPollHomeofficeDaysValid() &&
        this.isPollMonthsInOrganizationValid() &&
        this.isPollWorkingDaysValid() && this.isPollPlzValid()
        && this.isPollFrequenciesValid()
        && this.isTransportsUsedValid()
      ) {
        return true;
      } else {
        return false;
      }
    },
    setGeneralPollSettings(generalSettings) {
      if (generalSettings) {
        this.pollMonthsInOrganization = generalSettings.pollMonthsInOrganization;
        this.pollWorkingDays = generalSettings.pollWorkingDays;
        this.pollHomeofficeDays = generalSettings.pollHomeofficeDays;
        this.pollMonthsInOrganizationMax = generalSettings.pollMonthsInOrganizationMax;
        this.pollWorkingDaysMax = generalSettings.pollWorkingDaysMax;
        this.pollHomeofficeDaysMax = generalSettings.pollHomeofficeDaysMax;
      }
    },
    /***
     * create date object from a date string
     */
    setPollDate(val) {
      if (!val) {
        return;
      }
      const dateParts = val.split(".");

      let month, day, year;
      if (dateParts.length === 3) {
        day = Number(dateParts[0]);
        month = Number(dateParts[1]);
        year = Number(dateParts[2]);
      }
      let dateBuilder = new Date();
      dateBuilder.setFullYear(year);
      dateBuilder.setMonth(month - 1);
      dateBuilder.setDate(day);
      this.pollDate = dateBuilder;
    },
    setUsePoll(val) {
      this.usePoll = val;
    },
    setFrequencyType(val) {
      this.frequencyType = val;
    },
    setClientName(val) {
      this.clientName = val;
    },
    setPollType(val) {
      this.pollType = val;
    },
    setTitle(val) {
      this.title = val;
    },
    setIntroductionSteps(val) {
      this.introductionSteps = val;
    },
    setPollWorkingDays(val) {
      this.pollWorkingDays = val;
    },
    setPollHomeofficeDays(val) {
      this.pollHomeofficeDays = val;
    },
    setPollMonthsInOrganization(val) {
      this.pollMonthsInOrganization = val;
    },
    setPlz(plzStr) {
      this.pollPlz = plzStr;
    },
    /**
     * 
     * @param {*} obj contains freq (=frequency) and transportId 
     */
    addFrequency(obj) {
      this.frequencies.push(obj);
    },
    addNumberStars(obj) {
      this.numberStars.push(obj)
    },
    setNumberStars(num, i) {
      this.numberStars[i].freq = num;
    },
    resetFrequencies() {
      this.frequencies = [];
    },
    setFrequency(num, i) {
      this.frequencies[i].freq = num;
    },
    incrementFrequency(i) {
      this.frequencies[i].freq += 1;
    },
    decrementFrequency(i) {
      if (this.frequencies[i].freq > 0) {
        this.frequencies[i].freq -= 1;
      }
    },
    /**
     * 
     * @param {*} obj contains freq (=frequency) and transportId 
     */
    addNumberPassengers(obj) {
      this.numberPassengers.push(obj);
    },
    resetNumberPassengers() {
      this.numberPassengers = [];
    },
    setNumberPassengers(num, i) {
      this.numberPassengers[i].freq = num;
    },
    incrementNumberPassengers(i) {
      this.numberPassengers[i].freq += 1;
    },
    decrementNumberPassengers(i) {
      if (this.numberPassengers[i].freq > 0) {
        this.numberPassengers[i].freq -= 1;
      }
    },
    addTransportUsed() {
      this.transportUsed.push(false);
    },
    resetTransportUsed() {
      this.transportUsed = [];
    },
    setTransportUsed(val, i) {
      this.transportUsed.splice(i, 1, val);

    },
  },
});
