<template>
  <v-menu
    v-model="display"
    :close-on-content-click="false"
    :close-on-click="false"
    transition="scale-transition"
    offset-y
    :top="embedPositioningHack && !$vuetify.breakpoint.mobile"
    min-width="250px"
    style="z-index: 1000"
  >
    <!-- :menu-events We don't use v-menu's event bindings, but do them manually
         to improve keyboard navigation. -->
    <template v-slot:activator="{ attrs }">
      <v-text-field
        :style="colorOfInputElementsStyle"
        ref="textfield"
        v-bind="{ ...attrs, ...textFieldProps }"
        :disabled="disabled || pageLoading"
        :label="label"
        :value="formattedDatetime"
        clearable
        filled
        @click:clear="clearHandler"
        @click="toggle"
        @focus="toggle"
        @keydown.tab="resetPicker"
        @keydown.escape="resetPicker"
      ></v-text-field>
    </template>

    <!-- see :menu-events -->
    <v-card
      v-click-outside="{
        handler: () => (date ? okHandler() : resetPicker()),
        closeConditional: () => display,
        include: () => [$refs.textfield.$el],
      }"
    >
      <!-- customer-specific ID (md5) for tracking unlicensed use -->
      <span data-id="@@CUSTOMER_ID@@" />
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <v-icon>event</v-icon>
          </v-tab>
          <v-tab key="timer" :disabled="!dateSelected">
            <v-icon>access_time</v-icon>
          </v-tab>

          <v-tab-item key="calendar">
            <v-date-picker
              ref="calendar"
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              first-day-of-week="1"
              no-title
            ></v-date-picker>
          </v-tab-item>

          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="time"
              v-bind="timePickerProps"
              @click:minute="okHandler"
              scrollable
              width="250"
              format="24hr"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import format from "date-fns/format";
import parse from "date-fns/parse";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "12:00:00";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";

export default {
  name: "DateTimePicker",
  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    pageLoading: { type: Boolean, default: false},
    datetime: { type: [Date, String], default: null },
    disabled: { type: Boolean },
    label: { type: String, default: "" },
    textFieldProps: { type: Object },
    datePickerProps: { type: Object },
    timePickerProps: { type: Object },
    dateFormat: { type: String, default: DEFAULT_DATE_FORMAT },
    timeFormat: { type: String, default: "HH:mm" },
    embedPositioningHack: { type: Boolean },
    colorOfInputElementsStyle: {type: Object, default: ()=>({})}
  },
  data() {
    return {
      display: false,
      lastToggle: 0,
      activeTab: 0,
      changeSinceOpen: false,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? format(this.selectedDatetime, this.dateTimeFormat)
        : "";
    },
    selectedDatetime() {
      if (!this.date || !this.time) return null;

      let datetimeString = `${this.date} ${this.time}`;
      if (this.time.length === 5) datetimeString += ":00";
      return parse(
        datetimeString,
        `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
        new Date()
      );
    },
    dateSelected() {
      return !!this.date;
    },
  },
  methods: {
    init() {
      if (!this.datetime) return;
      let initDateTime;
      if (this.datetime instanceof Date) initDateTime = this.datetime;
      else if (
        typeof this.datetime === "string" ||
        this.datetime instanceof String
      )
        initDateTime = parse(this.datetime, this.dateTimeFormat, new Date());

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
      this.resetPicker();
    },
    toggle() {
      // debounce simultaneously firing focus & click events.
      const n = Date.now();
      if (n - this.lastToggle <= 400) return;
      this.lastToggle = n;

      if (this.display) this.resetPicker();
      else this.open();
    },
    open() {
      this.changeSinceOpen = false;
      this.display = true;
    },
    okHandler() {
      if (this.changeSinceOpen) this.$emit("input", this.selectedDatetime);
      this.$refs.textfield.focus();
      this.resetPicker();
    },
    clearHandler() {
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
      this.resetPicker();
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) this.$refs.timer.selectingHour = true;
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
  watch: {
    datetime: function () {
      this.init();
    },
    date() {
      this.changeSinceOpen = true;
    },
    time() {
      this.changeSinceOpen = true;
    },
  },
};
</script>

<style>
.v-picker__title {
  padding: 8px !important; /* there is no sass var for this.. */
}

.v-time-picker-clock__container {
  flex-basis: 0 !important; /* override hardcoded value to make sure the clock is round (see width=250) */
}
</style>
