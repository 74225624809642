import { fetchFrontendConfig, fetchConfig } from './api'

// wrapper to fetch config only once.
let configPromise
export function config() {
    return configPromise || (configPromise = fetchConfig());
}
// const config = () => configPromise || (configPromise = fetchConfig())
// export default config

// wrapper to fetch frontend config only once.
let frontendConfigPromise
export function frontendConfig() {
    if (frontendConfigPromise) {
        return frontendConfigPromise
    } else {
        frontendConfigPromise = fetchFrontendConfig();
        return frontendConfigPromise;
    }
    
}
