<template>
  <div
    style="cursor: pointer; border-radius: 23px"
    :class="boxClass"
    :style="borderStyle"
  >
    <div
      v-for="(transportPart, i) in computedTransportGroup"
      :key="i"
      :class="boxClass"
      :style="singleSymbolArrowStyle(i)"
    >
      <v-avatar
        class="mb-0"
        :color="isOverviewMode ? 'secondary !important' : `${color}`"
        size="40px"
        rounded="circle"
      >
        <v-avatar
          class="mb-3 avatar2"
          color="white !important"
          size="34px"
          rounded="circle"
          style="margin-top: 12px"
        >
          <v-icon v-if="transportPart.ui.materialIcon" color="primary">{{
            transportPart.ui.materialIcon
          }}</v-icon>
          <svg
            v-else-if="
              transportPart.ui.svgNameIcon &&
              transportPart.ui.svgNameIcon == 'high_speed_train'
            "
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="24.000000pt"
            height="24.000000pt"
            viewBox="0 0 24.000000 24.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(3.000000,22.000000) scale(0.0800000,-0.0800000)"
              :fill="$vuetify.theme.themes.light.primary"
              stroke="none"
            >
              <path
                d="M20 200 c0 -5 12 -10 28 -10 39 -1 102 -32 136 -69 l30 -31 -32 0
c-32 0 -43 -14 -19 -23 8 -4 7 -6 -3 -6 -8 0 -26 6 -40 14 -14 8 -42 14 -63
15 -36 0 -38 -2 -35 -27 2 -24 8 -29 36 -31 19 -2 41 0 50 4 10 4 35 6 56 4
31 -1 38 1 33 13 -3 9 2 17 14 20 26 7 24 19 -8 58 -44 52 -183 104 -183 69z
m40 -140 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m40 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"
              />
              <path
                d="M20 135 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11 0
-20 -7 -20 -15z"
              />
              <path
                d="M80 134 c0 -8 10 -14 26 -14 28 0 25 15 -6 23 -13 3 -20 0 -20 -9z"
              />
            </g>
          </svg>
          <!-- <img v-else-if="transport.ui.fileNameIcon" style="filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);" :src="`./icons/${transport.ui.fileNameIcon}.svg`"/> -->
          <!-- <img v-else-if="transport.ui.fileNameIcon" color="primary" :src="`./icons/${transport.ui.fileNameIcon}.png`"/> -->
        </v-avatar>
      </v-avatar>
      <!-- <span style="width: 20px">{{ transport.transportType }}</span> -->
      <v-icon
        style="margin-top: 1px"
        color="white"
        v-if="
          boxAlignment === 'vertical' &&
          i < computedTransportGroup.length - 1 &&
          computedTransportGroup.length > 1
        "
        >arrow_downward</v-icon
      >
      <v-icon
        style="margin-left: 1px"
        color="white"
        v-else-if="
          boxAlignment === 'horizontal' &&
          i < computedTransportGroup.length - 1 &&
          computedTransportGroup.length > 1
        "
        >arrow_right</v-icon
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "TransportSymbolsBox",

  props: {
    height: { type: Number },
    transports: { type: Array, default: () => [] },
    transport: { type: Object, default: () => ({}) },
    routes: { type: Array, default: () => [] },
    checkedArray: { type: Object, default: () => ({}) },
    routesByTransportType: { type: Object, default: () => ({}) },
    transportTypesArray: { type: Array, default: () => [] },
    updateCheckedArray: { type: Function, default: () => ({}) },
    geojsonStyles: { type: Array, default: () => [] },
    getStyleByIndex: { type: Function, default: () => {} },
    geojsonStyle: { type: Object, default: () => ({}) },
    isOverviewMode: { type: Boolean, default: true },
    color: { type: String, default: "" },
    boxAlignment: { type: String, default: "horizontal" },
  },
  emits: ["update:checkedArray"],
  methods: {
    /* css-style of a single display-box with a single symbol item and an arrow on the right side or bottom,
    depending on alignment of the box */
    singleSymbolArrowStyle(i) {
      let myStyle = {};
      const marginPx = 1;
      if (this.boxAlignment === "vertical") {
        // if the box-item is the last one within the box-group then set a margin-bottom
        myStyle = this.isLastItemByIndex(i)
          ? { "margin-bottom": marginPx + "px" }
          : { "margin-bottom": "0px" };
        // setting the margin for the left, right and top
        myStyle = {
          ...myStyle,
          "margin-left": marginPx + "px",
          "margin-right": marginPx + "px",
          "margin-top": marginPx + "px",
        };
      } else if (this.boxAlignment === "horizontal") {
        // if the box-item is the last one within the box-group then set a margin-right
        myStyle = this.isLastItemByIndex(i)
          ? { "margin-right": marginPx + "px" }
          : { "margin-right": "0px" };
        // setting the margin for the top, bottom and left
        myStyle = {
          ...myStyle,
          "margin-top": marginPx + "px",
          "margin-bottom": marginPx + "px",
          "margin-left": marginPx + "px",
        };
      }
      return myStyle;
    },
    /* check whether the box-item is the last one with the box-group */
    isLastItemByIndex(i) {
      return this.parseMultiTransportsFromTransport().length - 1 === i;
    },
    checkedArrayChanged(key, val) {
      this.updateCheckedArray(key, val);
    },
    updateFormData: function (transportType) {
      // this.$set(this.checkedArray, id, value);
      let val = this.checkedArray.hasOwnProperty(transportType)
        ? !this.checkedArray[transportType]
        : false;
      this.checkedArrayChanged(transportType, val);
      // this.checkedArray[transportType] = this.checkedArray.hasOwnProperty(
      //   transportType
      // )
      //   ? !this.checkedArray[transportType]
      //   : false;
    },
    getInitCheckedArray() {
      let checkedArrayLocal = {};
      this.transportTypesArray.forEach(
        (tType) => (checkedArrayLocal[tType] = true)
      );
      return checkedArrayLocal;
    },
    parseMultiTransportsFromTransport() {
      let transports = [];
      if (this.transport.firstMile) {
        transports.push(this.transport.firstMile);
      }
      if (this.transport.transportType === "rideAndCarsharing") {
        transports.push({
          ...this.transport,
          transportType: "carsharing",
          ui: { ...this.transport.ui, icon: "car_rental" },
        });
      } else if (this.transport.type === "carpooling") {
        transports.push({
          ...this.transport          
        });
      } else {
        transports.push(this.transport);
      }

      if (this.transport.lastMile) {
        transports.push(this.transport.lastMile);
      }
      return transports;
    },
  },
  data: () => ({
    expanded: false,

    // checkedArray: { car: true, bikeshare_Nextbike: true },
    // geojsonStyles: [
    //   { color: "#ff0000", weight: 4 },
    //   { color: "blue", weight: 4 },
    //   { color: "green", weight: 4 },
    //   { color: "black", weight: 4 },
    //   { color: "orange", weight: 4 },
    //   { color: "yellow", weight: 4 },
    // ],
    transportTypesKeys: [],
    transportGroup: [],
  }),

  computed: {
    computedTransportGroup() {
      // if transportGroup is already initialized, then return it, otherwise compute it
      if (this.transportGroup && this.transportGroup.length > 0) {
        return this.transportGroup;
      } else {
        this.transportGroup = this.parseMultiTransportsFromTransport();
        return this.transportGroup;
      }
    },
    boxClass() {
      return this.boxAlignment === "vertical"
        ? "flex-box-top-aligned-vertical"
        : "flex-box-left-aligned-horizontal";
    },
    borderStyle() {
      const color = this.isOverviewMode
        ? this.$vuetify.theme.themes.light.secondary
        : this.color;
      return { border: `2px solid`, color: color, "background-color": color };
    },
    availableRoutes() {
      if (
        this.routesByTransportType &&
        (!this.routes || this.routes.length === 0)
      ) {
        // cast transports to the form {transport: transportObject}
        // const routes = this.transports.map(t => new Object({ transport: t }));
        let uniqueTransportTypes = {}; //{ ...this.routesByTransportType };
        const routes = this.transports.forEach((t) => {
          uniqueTransportTypes[t.transportType] = { transport: t };
        });
        uniqueTransportTypes = uniqueTransportTypes;
        return uniqueTransportTypes;
      } else {
        let obj = {};
        if (!this.routesByTransportType) {
          return {};
        }
        Object.keys(this.routesByTransportType).forEach(
          (key) => (obj[key] = this.routesByTransportType[key][0])
        );
        return obj;
      }
    },
  },
  watch: {
    transport() {
      this.parseMultiTransportsFromTransport();
    },
  },
});
</script>
