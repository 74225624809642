import { defineStore } from "pinia";

export const useConfigStore = defineStore({
  id: "config",
  state: () => ({
    /**
   * @param pageLoading whether the page is loading or not
   * @param currentOrsApiIndex current API-key used for OpenRoute requests
   * @param orsApiKeys all available API-keys for OpenRoute
   * @param searchRunning status of the search request 
   */
    currentOrsApiIndex: 0,
    orsApiKeys: [],
    pageLoading: true,
    searchRunning: false,
    metricToSortRoutesBy: "time",
    allowPastDate: false,
    metricToJourneyThereTimeOrientation: "arrival"
  }),
  getters: {
    /**
     * return ORS api key of current index
     * @param {*} state 
     * @returns 
     */
    getOrsApiKey: (state) => {
      return state.orsApiKeys[state.currentOrsApiIndex];
    }
  },
  actions: {
    /**
     * rotate ORS index of the API-keys-array
     */
    switchOrsApiKey() {
      // rotate keys only if keys are stored
      if (this.orsApiKeys.length > 0) {
        this.currentOrsApiIndex = (this.currentOrsApiIndex + 1) % this.orsApiKeys.length;
      }
    },
    /**
     * setting the status of the loading status of the route search
     * @param {*} status status to set to like true or false
     */
    setPageLoading(status) {
      this.pageLoading = status;
    },
    /**
 * setting the status of search request
 * @param {*} status status to set to like true or false
 */
    setSearchRunning(status) {
      this.searchRunning = status;
    },
    /**
     * set keys only if it is defined and is an array
     * @param {*} keys 
     */
    setOrsApiKeys(keys) {
      if (keys && keys instanceof Array) {
        this.orsApiKeys = keys;
      }
    }, 
    setFrontendConfig(frontendConfig) {
      // only if sortByValue is passed set it, otherwise use the default value
       if (frontendConfig.metricToSortRoutesBy) {
        this.metricToSortRoutesBy = frontendConfig.metricToSortRoutesBy;
       }
    },
    setMetricToSortRoutesBy(val) {
      // only if sortByValue is passed set it, otherwise use the default value
      if (val) {
        this.metricToSortRoutesBy = val;
      }
    },
    setAllowPastDate(val) {
      if (val) {
        this.allowPastDate = val;
      }
    },
    setMetricToJourneyThereTimeOrientation(val) {
      if (val) {
        this.metricToJourneyThereTimeOrientation = val;
      }
    }
  },
});
