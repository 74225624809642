<template>
  <div>
    <h4>CLEVER UNTERWEGS MIT CLEVERROUTE.</h4>
    <p>
      Vergleiche deutschlandweit Kosten, Zeit, CO2-Emissionen und körperliche
      Bewegung auf deiner Route. CleverRoute zeigt dir deutschlandweit alle
      Optionen auf, um günstig, schnell, emissionsarm und komfortabel von A nach
      B zu gelangen. Neben deinem eigenen Fahrrad/Pedelec und Auto, erhältst du
      Informationen zum Öffentlichen Personenverkehr, Taxi-, Car- und
      Bike-Sharing-Angeboten sowie Kombinationen daraus.
    </p>

    <v-expansion-panels class="margin-right-5px margin-top-5px" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          ><span v-if="routes.length > 0">Übersicht der verfügbaren Verkehrsmittel</span>
          <span v-if="routes.length === 0">Übersicht aller Verkehrsmittel</span></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-start;

              width: 100%;
              overflow: auto;
              max-height: 240px;
            "
            v-if="checkedTransportsDict"
          >
          <div style="width: 50%">
            <p v-if="availableTransportsLength('modal')">Einfache Verkehrsmittel:</p>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
              "
            >
              <div :key="key" v-for="(value, key) in availableTransports('modal')">
                <div
                  style="cursor: pointer"
                  
                  @click="checkedTransportsChanged(key)"
                >
                  <TransportSymbolsBox
                    style="margin: 5px"
                    :transport="value.transport"
                    boxAlignment="vertical"
                    :color="
                      routes.length === 0 || !checkedTransportsDict[value.transport.transportType]
                        ? $vuetify.theme.themes.light.secondary
                        : $vuetify.theme.themes.light.primary
                    "
                    :isOverviewMode="routes.length === 0"
                  ></TransportSymbolsBox>
                </div>
              </div>
            </div>
            </div>
            <div  style="width: 50%">
            <p v-if="availableTransportsLength('intermodal')">Kombinierte Verkehrsmittel:</p>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
              "
            >
              <div
                :key="key"
                v-for="(value, key) in availableTransports('intermodal')"
              >
                <div
                  style="cursor: pointer"
                  @click="checkedTransportsChanged(key)"
                >
                  <TransportSymbolsBox
                    style="margin: 5px"
                    :transport="value.transport"
                    boxAlignment="vertical"
                    :color="
                      routes.length === 0 || !checkedTransportsDict[value.transport.transportType]
                        ? $vuetify.theme.themes.light.secondary
                        : $vuetify.theme.themes.light.primary
                    "
                    :isOverviewMode="routes.length === 0"
                  ></TransportSymbolsBox>
                </div>
              </div>
            </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header
          >   <span v-if="routes.length > 0">Verfügbare Sharing-Anbieter</span>
          <span v-if="routes.length === 0">Alle Sharing-Anbieter</span></v-expansion-panel-header
        >
        <v-expansion-panel-content>          
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
              overflow: auto;
              max-height: 240px;
            "
          >
            <template v-if="checkedProvidersDict">
              <div
                
                :key="key"
                v-for="(value, key) in availableSharingProviders"
              >
                <div
                class="flex-box-center-items-horizontal"
                  style="cursor: pointer;"
                  :style="checkedProvidersDict[value.providerName] ? {'border': `2px solid ${$vuetify.theme.themes.light.primary}`, 'border-radius': '8px'} : {}"
                  @click="checkedProvidersChanged(value.providerName)"
                >
                  <img
                    v-if="value.providerIcon"
                    :src="`./provider_logos/${value.providerIcon}.png`"
                    style="margin: 5px; width: 60px"
                    class="top-widget-logo"
                  />
                  <p
                    v-else-if="value.providerName"
                    style="margin: 5px; width: 60px"
                    class="top-widget-logo"
                  >
                    {{ value.providerName }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from "vue";
import TransportSymbolsBox from "./TransportSymbolsBox.vue";

export default Vue.extend({
  name: "MapOverviewToolbar",
  components: {
    TransportSymbolsBox,
  },
  props: {
    transports: { type: Array, default: () => [] },
    routes: { type: Array, default: () => [] },
    checkedTransportsDict: { type: Object, default: () => ({}) },
    checkedProvidersDict: { type: Object, default: () => ({}) },
    routesByTransportType: { type: Object, default: () => ({}) },
    updateCheckedTransports: { type: Function, default: () => ({}) },
    updateCheckedProviders: { type: Function, default: () => ({}) }
  },
  data: () => ({
    expanded: false,
    transportTypesKeys: [],
  }),
  methods: {
    checkedTransportsChanged: function (transportType) {
      let val = this.checkedTransportsDict.hasOwnProperty(transportType)
        ? !this.checkedTransportsDict[transportType]
        : false;
      this.updateCheckedTransports(transportType, val);
    },
    checkedProvidersChanged: function (transportType) {
      let val = this.checkedProvidersDict.hasOwnProperty(transportType)
        ? !this.checkedProvidersDict[transportType]
        : false;
      this.updateCheckedProviders(transportType, val);
    },
    availableTransportsLength(modality = undefined) {
      const routes = this.availableTransports(modality);
      return Object.keys(routes).length;
    },
    availableTransports(modality = undefined) {
      let relevantTransports;
      if (
        this.routesByTransportType &&
        (!this.routes || this.routes.length === 0)
      ) {
        // cast transports to the form {transport: transportObject}
        // const routes = this.transports.map(t => new Object({ transport: t }));
        let uniqueTransportTypes = {}; //{ ...this.routesByTransportType };
        this.transports.forEach((t) => {
          if (this.filterRouteByModality(t, modality)) {
            uniqueTransportTypes[t.transportType] = { transport: t };
          }
        });
        relevantTransports = uniqueTransportTypes;
      } else {
        let obj = {};
        if (!this.routesByTransportType) {
          return {};
        }
        Object.keys(this.routesByTransportType).forEach((key) => {
          if (
            this.filterRouteByModality(
              this.routesByTransportType[key][0].transport,
              modality
            )
          ) {
            obj[key] = this.routesByTransportType[key][0];
          }
        });
        relevantTransports = obj;
      }
      return relevantTransports;
    },
    filterRouteByModality(t, modality) {
      if (!modality) {
        return true;
      }

      if (
        modality === "intermodal" &&
        t.hasOwnProperty("isIntermodal") &&
        t.isIntermodal
      ) {
        return true;
      } else if (
        (modality === "modal" &&
          t.hasOwnProperty("isIntermodal") &&
          !t.isIntermodal) ||
        !t.hasOwnProperty("isIntermodal")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    availableSharingProviders() {
      if (
        this.routesByTransportType &&
        (!this.routes || this.routes.length === 0)
      ) {
        // cast transports to the form {transport: transportObject}
        // const routes = this.transports.map(t => new Object({ transport: t }));
        let providersTransportTypeMapArray = this.transports
          .filter(
            (t) =>
              t.transportType === "carsharing" ||
              t.transportType === "bikesharing"
          )
          .map((t) => ({
            transportType: t.transportType,
            providerIcon: t.ui.providerIcon,
            providerName: t.ui.providerName,
          }));
        let providersTransportTypeMapArrayFiltered =
          providersTransportTypeMapArray.reduce((prev, cur) => {
            return prev.findIndex(
              (val) => val.providerIcon === cur.providerIcon
            ) < 1
              ? prev.concat([cur])
              : prev;
          }, []);
        return providersTransportTypeMapArrayFiltered;
      } else {
        let obj = [];
        if (!this.routesByTransportType) {
          return [];
        }
        for (const [key, value] of Object.entries(this.routesByTransportType)) {

          if (key === "carsharing" || key === "bikesharing") {
            value.forEach((t) => {
              if (obj.find(objT => objT.providerName === t.transport.ui.providerName)) {
                  return
                }
              obj.push({
                transportType: t.transport.transportType,
                providerIcon: t.transport.ui.providerIcon,
                providerName: t.transport.ui.providerName,
              });
              });
          }
          else if (key === "rideAndCarsharing" || key === "rideAndBikesharing") {
            value.forEach((t) => {
                 if (obj.find(objT => objT.providerName === t.transport.lastMile.ui.providerName)) {
                  return
                }
              obj.push({
                transportType: t.transport.lastMile.transportType,
                providerIcon: t.transport.lastMile.ui.providerIcon,
                providerName: t.transport.lastMile.ui.providerName,
              });
              });
          }
        }
        // Object.keys(this.routesByTransportType).forEach((key) => {
        //   if (obj.find(t => t.)
        //   if (key === "carsharing") {
        //     this.routesByTransportType[key].forEach((t) => {
        //       obj.push({
        //         transportType: t.transport.transportType,
        //         providerIcon: t.transport.ui.providerIcon,
        //         providerName: t.transport.ui.providerName,
        //       });
        //     });
        //   } else if (key === "bikesharing") {
        //     this.routesByTransportType[key].forEach((t) =>
        //       obj.push({
        //         transportType: t.transport.transportType,
        //         providerIcon: t.transport.ui.providerIcon,
        //         providerName: t.transport.ui.providerName,
        //       })
        //     );
        //   } else if (key === "rideAndCarsharing") {
        //     this.routesByTransportType[key].forEach((t) => {
        //       obj.push({
        //         transportType: t.transport.lastMile.transportType,
        //         providerIcon: t.transport.lastMile.ui.providerIcon,
        //         providerName: t.transport.lastMile.ui.providerName,
        //       });
        //     });
        //   } else if (key === "rideAndBikesharing") {
        //     this.routesByTransportType[key].forEach((t) => {
        //       obj.push({
        //         transportType: t.transport.lastMile.transportType,
        //         providerIcon: t.transport.lastMile.ui.providerIcon,
        //         providerName: t.transport.lastMile.ui.providerName,
        //       });
        //     });
        //   }
        // });
        return obj;
      }
    },
  },
  watch: {
    routesByTransportType(newVal, oldVal) {
      //console.log("routesBy changed:", newVal, " - ", oldVal);
    },
  },
});
</script>
